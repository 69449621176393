import React from "react";
import { string, arrayOf, shape, bool } from "prop-types";
import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import Heading from "../../Base/HeadingBuilder";
import ProductCard from "../../Base/ProductCard";
import useStartMotion from "../../../hooks/use-start-motion";

const variants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const fadeVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
  },
};

const ProductsWithLinks = ({
  className,
  title,
  subtext,
  products,
  animations,
  symbol,
}) => {
  const { ref, controls } = useStartMotion();

  return (
    <section
      className={`
        max-w-1440 w-full px-4 lg:px-40
        ${className}
    `}
      ref={ref}
      data-cy="products-with-links"
    >
      <motion.div
        animate={controls}
        initial={isMobileOnly || !animations ? "visible" : "hidden"}
        variants={variants}
        transition={{ delay: 0.1, duration: 0.8 }}
      >
        <Heading
          level={2}
          injectionType={1}
          withBlueSymbol
          symbol={symbol}
          className="xl:max-w-80-percent whitespace-pre-line"
        >
          {title}
        </Heading>
      </motion.div>

      <motion.p
        className={`
        text-p text-black-300
        mb-10 lg:mb-20
        lg:max-w-70-percent xl:max-w-50-percent
      `}
        animate={controls}
        initial={isMobileOnly || !animations ? "visible" : "hidden"}
        variants={variants}
        transition={{ delay: 0.7, duration: 0.8 }}
      >
        {subtext}
      </motion.p>

      <div
        className={` 
          grid place-items-center 
          grid-cols-1 md:grid-cols-2 xl:grid-cols-3 
          gap-7
        `}
        // animate={controls}
        // initial={isMobileOnly || !animations ? "visible" : "hidden"}
        // variants={fadeVariants}
        // transition={{ delay: 1.2, duration: 0.8 }}
      >
        <ProductCard {...products[0]} />
        <ProductCard className="" {...products[1]} />
        <ProductCard {...products[2]} />
      </div>
    </section>
  );
};

ProductsWithLinks.propTypes = {
  className: string,
  title: string.isRequired,
  subtext: string,
  products: arrayOf(
    shape({ title: string, linkTo: string, imageName: string })
  ),
  animations: bool,
  symbol: string,
};

ProductsWithLinks.defaultProps = {
  className: "",
  subtext: "",
  symbol: "?",
  products: [],
  animations: true,
};

export default ProductsWithLinks;
